import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {COLORS, ROUTES} from '../../constants';
import Collapsible from 'react-collapsible';
import LOGO from '../../assets/logowhite.png';
import {BookButton} from '../../styled';
import img01small from '../../assets/chairstartimage2.jpg';
import img01big from '../../assets/chairstartimage5.jpg';
//import img01 from '../../assets/images/new/IMG_8818.jpeg';


import {
  TreatmentContainer,
  TreatmentTitle,
  TreatmentDescription,
  LogoBookButtonWrapper,
} from '../../styled';

const CollapsibleLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.2em;
  padding: 0 10px 0;
  border: 1px solid ${COLORS.gold};
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin: 5px 0;

  // media queries
//  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 0 25px 0;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    padding: 0 25px 0;
  }
 
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    padding: 0 25px 0;

  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    padding: 0 25px 0;

  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    padding: 0 25px 0;

  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    padding: 0 25px 0;

  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {

  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {

  }
  
`;

const OpenLabel = styled.div`
  right: 10px;
  position: absolute;
  font-size: 1.5em;
`;

const Label = ({children, isOpen}) => {
  return (
    <CollapsibleLabel>
      {children}
      {isOpen 
        ? <OpenLabel>-</OpenLabel>
        : <OpenLabel>+</OpenLabel>
      }
    </CollapsibleLabel>
  );
};

const InsculptChair = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = React.useState({});

  return (
    <div style={{width: '100%'}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{marginTop: '100px', position: 'relative', width: '100%', height: '50vh', background: COLORS.black}}>
          <img
            src={window.innerWidth < 1400 ? img01small : img01big} alt="Image of table with Sax and the City text"
            style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(80%)'}}
          />
        </div>
        <LogoBookButtonWrapper>
          <img src={LOGO} style={{width: '50%'}} />
          <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
        </LogoBookButtonWrapper>
      </div>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Bäckenbottenträning Malmö</TreatmentTitle>
          <TreatmentDescription>
            Är en unik metod som stärker din bäckenbottenmuskulatur. Både män och kvinnor kan stärka sin muskulatur för att lindra besvär med inkontinens, bäckenbottenproblem efter förlossning och/eller nedsatt intim tillfredsställelse. 
            Över 60% av alla kvinnor lider någon gång av urinläckage. Det kan vara när man tränar, nyser eller anstränger sig.
          </TreatmentDescription>
          <TreatmentDescription>
            Insculpt chair använder EMS, elektromagnetisk stimulering för att stimulera och stärka muskulaturen i bäckenbotten, inte bara de som tränas av knipövningar.  
            Dessa starkare bäckenbottenmuskler gör att du kan njuta av dina dagliga aktiviteter utan rädsla för bla urinläckage.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Vi rekommenderar att du först ta en kur med minst 10 behandlingar, 2 gånger i veckan (med 2 vilodagar).</b>
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Vid svåra problem kan det krävas 2 kurer.</b>
          </TreatmentDescription>
          <TreatmentDescription>
            Därefter kan du underhålla resultat med minst en gång/månad.
          </TreatmentDescription>
          <TreatmentDescription>
            Bäckenbottenmusklerna är det lager av muskler som stöder bäckenorganen och sträcker sig över bäckenbotten. Starka bäckenbottenmuskler ger kontroll över blåsan och tarmen. 
          </TreatmentDescription>
          <TreatmentDescription>
            <ul>
              <li>Icke-kirurgisk & smärtfri</li>
              <li>Inga farliga kemikalier</li>
              <li>Ökar cirkulationen</li>
              <li>Stärker muskulaturen</li>
              <li>Icke terapeutledd behandlingbehandlingar</li>
            </ul>
          </TreatmentDescription>
          <TreatmentDescription>
            Insculpt chair använder elektromagnetisk energi för att leverera tusentals supramaximala sammandragningar i bäckenbottenmusklerna under en enda behandling.
          </TreatmentDescription>
          <TreatmentDescription>
            12 000 knip under 28 minuter
          </TreatmentDescription>
          <TreatmentDescription>
            Under en behandling sitter du FULLT PÅKLÄDD i EMS-stolen och kan t.ex. läsa en bok.
            Behandlingen är icke-invasiv behandling, med andra ord finns inga risker för brännskador, ärrbildning eller svullnad.
          </TreatmentDescription>
          <TreatmentDescription>
            INSCULPT använder elektromagnetiska impulser som tränger igenom huden och fettlagren och träffar muskelfibrerna (muskelcellerna). Det skapar muskelsammandragningar som är mer effektiva och djupa än de viljestyrda som vi framkallar vid träning.
          </TreatmentDescription>
          <TreatmentDescription>
            Tekniken är baserad på omfattande forskning och vetenskapliga metoder.
          </TreatmentDescription>
          <Collapsible onClose={() => setOpen({five: false})} onOpen={() => setOpen({five: true})} trigger={<Label isOpen={open['five']}>Fördelar Insculpt Chair</Label>}>
            <TreatmentDescription>
              INSCULPT CHAIR använder elektromagnetisk energi för att leverera tusentals supramaximala sammandragningar i bäckenbottenmusklerna under en enda behandling.
            </TreatmentDescription>
            <TreatmentDescription>
              Under en behandling sitter du FULLT PÅKLÄDD i EMS-stolen och kan t.ex. läsa en bok.
            </TreatmentDescription>
            <TreatmentDescription>
              BEHANDLINGEN är icke-invasiv behandling, med andra ord finns inga risker för brännskador, ärrbildning eller svullnad.
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({one: false})} onOpen={() => setOpen({one: true})} trigger={<Label isOpen={open['one']}>Kan man träna bort inkontinens?</Label>}>
            <TreatmentDescription>
            <b>Hemmaträning</b>
          </TreatmentDescription>
          <TreatmentDescription>
            Bäckenbottenträning kan hjälpa dig som har besvär med ansträngningsinkontinens eller med trängningsinkontinens. Träningen stärker musklerna som finns runt ändtarmen, slidan och urinröret. Det hjälper dig att hålla tätt när du anstränger dig. För att träningen ska få bra effekt är det viktigt att du tränar rätt muskler.
          </TreatmentDescription>
            <TreatmentDescription>
              <b>Behandling</b>
            </TreatmentDescription>
            <TreatmentDescription>
              Skillnaden är att INSCULPT CHAIR använder elektromagnetisk energi för att leverera tusentals supramaximala sammandragningar i bäckenbottenmusklerna under en enda behandling.
            </TreatmentDescription>
              <TreatmentDescription>
                Behandlingen är väldigt effektiv mer intensiv än knippövningar. Behandlingen kan däremot hjälpa dig att få kontakt med muskulaturen och sedan fortsätta med knipövningar hemma.
              </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({two: false})} onOpen={() => setOpen({two: true})} trigger={<Label isOpen={open['two']}>Vilka skall inte göra behandlingen?</Label>}>
            <TreatmentDescription>
              För din säkerhet, vänligen beakta följande kontraindikationer innan behandling:
            </TreatmentDescription>
            <TreatmentDescription>
              <ul>
                <li>Nyligen genomförd kirurgi på området</li>
                <li>Pacemaker</li>
                <li>Graviditet</li>
                <li>Cancer</li>
                <li>Infektion i kroppen</li>
                <li>Metall- eller elektroniska implantat på behandlingsstället och inom 10 cm från behandlingsområdet</li>
                <li>Epilepsi</li>
                <li>Läkemedelspump-implantat</li>
                <li>Stroke eller hjärtinfarkt de senaste 6 månaderna, eller svagt hjärta</li>
                <li>Hjärnskador eller hjärnoperationer</li>
                <li>Defibrillator, neurostimulator eller andra elektroniska implantat</li>
                <li>Kopparspiral</li>
                <li>Inopererade metaller i bäckenområdet</li>
                <li>Pulmonalisinsufficiens</li>
                <li>Feber</li>
                <li>Blödarsjukdomar</li>
                <li>Svåra hjärt- eller lungsjukdomar</li>
                <li>Maligna tumörer</li>
              </ul>
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({four: false})} onOpen={() => setOpen({four: true})} trigger={<Label isOpen={open['four']}>Vad är inkontinens?</Label>}>
            <TreatmentDescription>
              Det finns flera olika typer av inkontinens.
            </TreatmentDescription>
            <TreatmentDescription>
              <b>Ansträngningsinkontinens</b>
            </TreatmentDescription>
            <TreatmentDescription>
              Detta är den vanligaste formen av inkontinens.
            </TreatmentDescription>
            <TreatmentDescription>
              Patienter med denna typ av problematik upplever läckage vid ansträngning så som hosta, nysning, skratt, hopp, träning eller andra fysiska aktiviteter.
              Ansträngningsinkontinensen grundar sig ofta i ålder, graviditet, förlossning, östrogenbrist och övervikt men kan även bero på andra faktorer.
            </TreatmentDescription>
            <TreatmentDescription>
              <b>Trängningsinkontinens/överaktiv blåsa</b>
            </TreatmentDescription>
            <TreatmentDescription>
              Vid trängningsinkontinens drar urinblåsans muskel ihop sig under fyllnadsfasen, trots väldigt liten urinvolym.
              Det tros bero på en störning i nervsignalen från hjärna och ryggmärg som initierar blåstömning
            </TreatmentDescription>
            <TreatmentDescription>
              <b>Blandinkontinens</b>
            </TreatmentDescription>
            <TreatmentDescription>
              Blandinkontinens är en kombination av de båda olika typerna ovan. Vissa kan uppleva mer av den ena varianten och mindre av den andra.
              Det tros bero på en störning i nervsignalen från hjärna och ryggmärg som initierar blåstömning
            </TreatmentDescription>
          </Collapsible>
          <Collapsible onClose={() => setOpen({three: false})} onOpen={() => setOpen({three: true})} trigger={<Label isOpen={open['three']}>Om behandlingen</Label>}> 
            <TreatmentDescription>
              <b>Behandlingstid</b>
            </TreatmentDescription>
            <TreatmentDescription>
              Effektiv behandlingstid är 28 minuter/tillfälle men räkna med att ditt besök tar 40-45 minuter.
            </TreatmentDescription>
            <TreatmentDescription>
              Efter 8-10 behandlingar märker de flesta ett bra resultat.
            </TreatmentDescription>
          </Collapsible>
        </div>
      </TreatmentContainer>
    </div>
  );
};

export default InsculptChair;
