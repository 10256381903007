import React from 'react';
import {useHistory} from 'react-router-dom';
import {COLORS, ROUTES} from '../../constants';
import LOGO from '../../assets/logowhite.png';
import img01 from '../../assets/images/new/IMG_9698.jpeg';
import {BookButton} from '../../styled';

import {
  TreatmentContainer,
  TreatmentTitle,
  TreatmentDescription,
  LogoBookButtonWrapper,
} from '../../styled';

const PigmentVessels = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{width: '100%'}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{marginTop: '100px', position: 'relative', width: '100%', height: '50vh', background: COLORS.black}}>
          <img
            src={img01} alt="Image of table with Sax and the City text"
            style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(80%)'}}
          />
        </div>
        <LogoBookButtonWrapper>
          <img src={LOGO} style={{width: '50%'}} />
          <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
        </LogoBookButtonWrapper>
      </div>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Behandling av pigmentfläckar med laser Malmö</TreatmentTitle>
          <TreatmentDescription>
            <h4>Fläckar så som pigmentfläckar och solfläckar är ofta kosmetiskt störande och önskvärt att ta bort. Med den senaste lasertekniken behandlar vi effektivt fläckar.</h4>
          </TreatmentDescription>
          <TreatmentDescription>
            Fläckar i ansiktet och på kroppen uppstår lätt genetiskt eller på grund av solen. De kan även uppstå på grund av hormoner eller medicin, till exempel i samband med en graviditet. 
          </TreatmentDescription>
          <TreatmentDescription>
            Vi erbjuder den absolut senaste och mest effektiva laserteknologin från Mediostar, vilket ger dig ett bättre resultat med färre behandlingar.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Behandlingen</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Inför varje behandling gör vi en noggrann koll av området vi ska behandla och tar fram en personlig behandlingsplan. Om behandlingen önskas påbörjas vid samma besökstillfälle börjar vi med att rengöra huden. Själva behandlingen är tidseffektiv och sker genom att vi för ett munstycke över huden. En varm känsla uppstår under behandlingen. Vår laser Mediostar har ett inbyggt kylningssystem som vi ställer in efter din hud, som konsekvent kyler före, under och efter varje puls. Det ger en effektiv, men ändå bekväm, säker och trygg behandling.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Resultat</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Resultatet efter varje behandling är individuellt och sker gradvis. För varje behandling blir pigmenten ljusare. Huden kommer också att kännas mjukare och få mer lyster.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Hur fungerar det?</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Pigmentfläckar kan uppstå genetiskt eller av yttre påfrestningar och går att behandla med laser. Lasern behandlar pigmentfläckar genom att skjuta ut ett ljus som skapar värme och gör att pigmenten ljusnar och blir mindre synliga. Vår laser Mediostar är den senaste generationens laserteknologi som levererar högre energi vid större område och kortare pulsvaraktighet, vilket ger dig ett bättre resultat på kortare tid och färre behandlingar.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Vilken typ av pigment kan vi behandla?</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            <ul>
              <li>Pigmentfläckar</li>
              <li>Pigmentförändringar</li>
              <li>Solskador</li>
            </ul>
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Efter behandlingen</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Efter behandlingen kan det uppstå en lätt rodnad som avtar efter någon till några timmar. Ibland uppstår även svullnader, som också försvinner efter något dygn. Pigmentfläckar antar övergående en mörkare färg innan de bleknar. Vi rekommenderar att du undviker solexponering i minst 4 veckor efter varje behandling.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
    </div>
  );
};

export default PigmentVessels;
